import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Pre Open WOD 3!`}</strong></p>
    <p><strong parentName="p"><em parentName="strong">{`RX Division`}</em></strong></p>
    <p>{`13:00 AMRAP of:`}</p>
    <p>{`10-Back Squats (135/95)`}</p>
    <p>{`15-GHD Situps`}</p>
    <p>{`15-Hand Release Pushups (Feet touching each other, body planked out,
full extension at the top)`}</p>
    <p>{`15-Wall Balls (20/14`}{`#`}{`, 10’/9′)`}</p>
    <p>{`10-Back Squats (185/125) (Using same bar, must load your own weight)`}</p>
    <p>{`15-GHD Situps`}</p>
    <p>{`15-HR Pushups`}</p>
    <p>{`15-Wall Balls`}</p>
    <p>{`10-Back Squats (225/155)`}</p>
    <p>{`15-GHD Situps`}</p>
    <p>{`15-HR Pushups`}</p>
    <p>{`15-Wall Balls`}</p>
    <p>{`10-Back Squats (275/185)`}</p>
    <p>{`15-GHD Situps`}</p>
    <p>{`15-HR Pushups`}</p>
    <p>{`15-Wall Balls`}</p>
    <p>{`10-Back Squats (315/215)`}</p>
    <p>{`15-GHD Situps`}</p>
    <p>{`15-HR Pushups`}</p>
    <p>{`15-Wall Balls`}</p>
    <p>{`*`}{`If time remains start over at 135/95`}{`#`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Scaled/Masters Divisions`}</em></strong></p>
    <p>{`13:00 AMRAP of:`}</p>
    <p>{`10-Back Squats (95/65)`}</p>
    <p>{`15-Situps (With or without Ab Mat, Hand must touch floor behind your
head at the bottom of each rep and must touch the floor by your shoes at
the top of each rep, no crunches)`}</p>
    <p>{`15-Ab Mat Pushups (Ab Mat under chest, chest must touch ab mat, full
extension at the top)`}</p>
    <p>{`15-Wall Balls (14/10`}{`#`}{`, 10’/9′)`}</p>
    <p>{`10-Back Squats (135/95) (Using same bar, must load your own weight)`}</p>
    <p>{`15-Situps`}</p>
    <p>{`15-Pushups`}</p>
    <p>{`15-Wall Balls`}</p>
    <p>{`10-Back Squats (155/105)`}</p>
    <p>{`15-Situps`}</p>
    <p>{`15-Pushups`}</p>
    <p>{`15-Wall Balls`}</p>
    <p>{`10-Back Squats (185/125)`}</p>
    <p>{`15-Situps`}</p>
    <p>{`15-Pushups`}</p>
    <p>{`15-Wall Balls`}</p>
    <p>{`10-Back Squats (205/145)`}</p>
    <p>{`15-Situps`}</p>
    <p>{`15-Pushups`}</p>
    <p>{`15-Wall Balls`}</p>
    <p><em parentName="p">{`*`}{`If time remains start over at 95/65`}{`#`}</em></p>
    <p><em parentName="p">{`*`}{`Please pair up in class to perform this workout.  Judges are to spot
their partners and count reps only.  Use spotting arms for safety. `}</em></p>
    <p><em parentName="p">{`*`}{`*`}{`If additional time remains in class: Pistols 3×8/leg & GHR’s 3×8`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Saturday, February 4th, we will have our 3rd Annual Sports
Nutrition seminar will Dawn from Sports Nutrition 2 Go in Cincinnati.
Want to learn the exact number of calories your body burns each day?
Metabolic Testing will be offered before and after the seminar as
well!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      